export default [
  {
    headerTitle: {
      en: "Consultation",
      hu: "Konzultáció",
      de: "Konsultation",
    },
    items: [
      {
        name: {
          en: "Consultation fee",
          hu: "Konzultációs díj",
          de: "Beratungsgebühr",
        },
        price: "13.000,- Ft",
      },
      {
        name: {
          en: "Maternal screening, counseling",
          hu: "Kismama szűrés, tanácsadás",
          de: "Mütterscreening, Beratung",
        },
        price: "10.000,- Ft",
      },
    ],
  },
  {
    headerTitle: {
      hu: "Röntgen, CT",
      en: "X-ray, CT",
      de: "Röntgen, CT",
    },
    items: [
      {
        name: {
          en: "Jaw joint CT",
          de: "Kiefer-Gelenk CT",
          hu: "Állkapocsízület CT",
        },
        price: "23.000,- Ft",
      },
      {
        name: {
          en: "Head and neck surgery CT",
          de: "Kopf-und Hals-Chirurgie CT",
          hu: "Fej - nyak sebészeti CT",
        },
        price: "23.000,- Ft",
      },
      {
        name: {
          en: "Digital x-ray of periapical 1 tooth",
          de: "Digitale Röntgenaufnahme von periapikalem 1 Zahn",
          hu: "Digitális röntgen periapicalis 1db fogról",
        },
        price: "4.000,- Ft",
      },
      {
        name: {
          en: "X-ray panorama digital",
          de: "Röntgen panorama digital",
          hu: "Röntgen panoráma digitális",
        },
        price: "8.500,- Ft",
      },
      {
        name: {
          en: "X-ray digital full",
          de: "Röntgen digital voll",
          hu: "Röntgen digitális tele",
        },
        price: "8.500,- Ft",
      },
      {
        name: {
          en: "Data carrier listing",
          de: "Auflistung der Datenträger",
          hu: "Adathordozó kiírás",
        },
        price: "+ 2.000,- Ft",
      },
    ],
  },
  {
    headerTitle: {
      en: "Zahnhygiene",
      de: "Dental higenia",
      hu: "Dentálhigénia",
    },
    items: [
      {
        name: {
          en: "Removal of tartar per jaw",
          de: "Zahnsteinentfernung pro Kiefer",
          hu: "Fogkő eltávolítás állcsontonként",
        },
        price: "12-18.000,- Ft",
      },
      {
        name: {
          en: "Plaque painting, instruction, motivation",
          de: "Plakettenmalerei, Anleitung, Motivation",
          hu: "Plakkfestés, instruálás, motiválás",
        },
        price: "14.000,- Ft",
      },
      {
        name: {
          en: "Smile line design",
          de: "Design der Lächelnlinie",
          hu: "Mosolyvonal kialakítás",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Tooth whitening (home) rails + gel per jaw",
          de: "Zahnaufhellungsschienen (zu Hause) + Gel pro Kiefer",
          hu: "Fogfehérítés (otthoni) sín + gél állkapcsonként",
        },
        price: "45.000,- Ft/ állcsont",
      },
      {
        name: {
          en: "With ZOOM lamp (complete tooth, 1 time)",
          de: "Mit ZOOM Lampe (kompletter Zahn, 1 Mal)",
          hu: "ZOOM lámpával (teljes fogazat, 1 alkalom)",
        },
        price: "105.000,- Ft",
      },
      {
        name: {
          en: "Arc registration",
          de: "Lichtbogen registrierung",
          hu: "Arcíves regisztráció",
        },
        price: "25.000,- Ft",
      },
      {
        name: {
          en: "Determination of central occlusion by support pin registration",
          de: "Bestimmung der zentralen Okklusion durch Registrierung des Stützstifts",
          hu: "Centrális okklúzió meghatározás támasztócsapos regisztrátum segítségével",
        },
        price: "35.000,- Ft",
      },
    ],
  },
  {
    headerTitle: {
      en: "Children's Dentistry",
      de: "Kinderzahnheilkunde",
      hu: "Gyermekfogászat",
    },
    items: [
      {
        name: {
          en: "Milk tooth removal",
          de: "Milchzahnentfernung",
          hu: "Tejfog eltávolítás",
        },
        price: "10.000, - Ft tól",
      },
      {
        name: {
          en: "Milk tooth filling",
          de: "Milchzahnfüllung",
          hu: "Tejfog tömés",
        },
        price: "12.0000 - 15.000,- Ft",
      },
      {
        name: {
          en: "Milk tooth trapping",
          de: "Milchzahnfallen",
          hu: "Tejfog trepanálás",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Temporary filling, medicinal filling",
          de: "Temporäre Füllung, medizinische Füllung",
          hu: "Ideiglenes tömés, gyógytömés",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Final filling trioplast",
          de: "Die endgültige Füllung, Trioplast",
          hu: "Végleges tömés triopaszta",
        },
        price: "10.000,- Ft tól",
      },
      {
        name: {
          en: "Furrow",
          de: "Fissur",
          hu: "Barázdazárás",
        },
        price: "11.000,-Ft / foganként",
      },
      {
        name: {
          en: "Extended furrow",
          de: "Erweiterter Fissur",
          hu: "Kiterjesztett barázdazárás",
        },
        price: "15.000,-Ft / foganként",
      },
      {
        name: {
          en: "Milk tooth grinding",
          de: "Milchzahnknirschen Fissur",
          hu: "Tejfog elcsiszolás",
        },
        price: "8.000,-Ft",
      },
    ],
  },
  {
    categoryTitle: {
      en: "Adult treatments",
      de: "Behandlungen für Erwachsene",
      hu: "Felnőtt kezelések",
    },
    headerTitle: {
      en: "Root treatments (cofferdam isolation only)",
      de: "Wurzelbehandlungen (nur Kofferdammisolierung)",
      hu: "Gyökérkezelések (kizárólag kofferdam izolálásban)",
    },
    items: [
      {
        name: {
          en: "Front tooth (1 channel) Trepanation, length determination, medicated filling",
          de: "Frontzahn (1 Kanal) Trepanation, Längenbestimmung, medikamentöse Füllung",
          hu: "Frontfog (1 csatorna) Trepanálás, hossz meghatározás, gyógyszeres tömés",
        },
        price: "28.000,- Ft",
      },
      {
        name: {
          en: "Front teeth (1 channel) root canal treatment",
          hu: "Frontfog (1 csatorna) Gyökértömés",
          de: "Vorderzahn (1 Kanal) Wurzelfüllung",
        },
        price: "30.000,- Ft",
      },
      {
        name: {
          en: "Root treatment scrub (2 channels) Trepaning, length determination, medicated filling",
          de: "Wurzelbehandlungspeeling (2 Kanäle) Trepaning, Längenbestimmung, medikamentöse Füllung",
          hu: "Gyökérkezelés kisörlő (2 csatorna) Trepanálás, hossz meghatározás, gyógyszeres tömés",
        },
        price: "30.000,- Ft",
      },
      {
        name: {
          en: "Root treatment scrub (2 channels) root canal treatment",
          hu: "Gyökérkezelés kisörlő (2 csatorna) Gyökértömés",
          de: "Wurzelbehandlungspeeling (2 Kanäle) Wurzelfüllung",
        },
        price: "32.000,- Ft",
      },
      {
        name: {
          en: "Root canal moral (3 channels) Trepaning, length determination, medicated filling",
          de: "Wurzelkanalbehandlung (3 Kanäle) Trepaning, Längenbestimmung, medikamentöse Füllung",
          hu: "Gyökérkezelés nagyörlő (3 csatorna) Trepanálás, hossz meghatározás, gyógyszeres tömés",
        },
        price: "33.000,- Ft",
      },
      {
        name: {
          en: "Root treatment moral (3 channels) root canal treatment",
          de: "Wurzelbehandlung große Milbe (3 Kanäle) Wurzelfüllung",
          hu: "Gyökérkezelés nagyörlő (3 csatorna) Gyökértömés",
        },
        price: "35.000,- Ft",
      },
      {
        name: {
          en: "Root treatment moral (4 channels) root canal treatment",
          de: "Wurzelbehandlung große Milbe (4 Kanäle) Wurzelfüllung",
          hu: "Gyökérkezelés nagyörlő (4 csatorna) Gyökértömés",
        },
        price: "35.000,- Ft tól",
      },
      {
        name: {
          en: "Medicinal filling",
          de: "Medizinische Füllung",
          hu: "Gyógyszeres tömés",
        },
        price: "20.000,- Ft",
      },
    ],
  },
  {
    headerTitle: {
      en: "Root Canal Treatment removal",
      de: "Wurzelfüllung entfernen",
      hu: "Gyökértömés eltávolítás",
    },
    items: [
      {
        name: {
          en: "Root Canal Treatment removal - front tooth - cleaning, medicated filling",
          de: "Entfernen der Wurzeldichtung - Vorderzähne - Reinigung, medizinische Füllung",
          hu: "Gyökértömés eltávolítás - frontfog - áttisztítás, gyógyszeres tömés",
        },
        price: "30.000,- Ft",
      },
      {
        name: {
          en: "Root Canal Treatment removal - premolar - cleaning, medicated filling",
          de: "Wurzelkissen entfernen - Schaber - Reinigung, medizinische Füllung",
          hu: "Gyökértömés eltávolítás - kisörlő - áttisztítás, gyógyszeres tömés",
        },
        price: "32.000,- Ft",
      },
      {
        name: {
          en: "Root Canal Treatment removal - molar - cleaning, medicated filling",
          de: " Wurzelstockentfernung - Großer Schalldämpfer - Reinigung, medizinische Füllung",
          hu: "Gyökértömés eltávolítás - nagyörlő - áttisztítás, gyógyszeres tömés",
        },
        price: "35.000,- Ft",
      },
    ],
  },
  {
    headerTitle: { en: "Fillings", de: "Füllungen", hu: "Tömések" },
    items: [
      {
        name: {
          en: "Light-curing, tooth-colored, aesthetic fillings - 1 surface",
          de: "Lichthärtende, zahnfarbene, ästhetische Füllungen - 1 Oberfläche",
          hu: "Fényrekötő, fogszínű, esztétikus tömések - 1 felszín",
        },
        price: "27.000, Ft",
      },

      {
        name: {
          en: "Light-curing, tooth-colored, aesthetic fillings - 2 surfaces",
          de: "Lichthärtende, zahnfarbene, ästhetische Füllungen - 2 Oberflächen",
          hu: "Fényrekötő, fogszínű, esztétikus tömések - 2 felszín",
        },
        price: "30.000, Ft",
      },
      {
        name: {
          en: "Light-curing, tooth-colored, aesthetic fillings - 3 surfaces",
          de: "Lichthärtende, zahnfarbene, ästhetische Füllungen – 3 Oberflächen",
          hu: "Fényrekötő, fogszínű, esztétikus tömések - 3 felszín",
        },
        price: "33.500, Ft",
      },
      {
        name: {
          en: "Light-curing, tooth-colored, aesthetic fillings - more surfaces",
          de: "Lichthärtende, zahnfarbene, ästhetische Füllungen - mehr Oberflächen",
          hu: "Fényrekötő, fogszínű, esztétikus tömések - több felszín",
        },
        price: "35.000, Ft tól",
      },
      {
        name: {
          en: "Stump construction",
          de: "Stumpfbau",
          hu: "Csonkfelépítés",
        },
        price: "20.000, Ft",
      },
      {
        name: {
          en: "Medicated lining",
          de: "Medizinisches Futter",
          hu: "Gyógyszeres alábélelés",
        },
        price: "3.000, Ft",
      },
      {
        name: {
          en: "Cervical filling",
          de: "Füllung des Halses",
          hu: "Nyaki tömés",
        },
        price: "12.000 - 15.000, Ft",
      },
      {
        name: {
          en: "Pre-endo structure",
          de: "Pre-Endo-Struktur",
          hu: "Pre-endo felépítés",
        },
        price: "20.000, Ft",
      },
    ],
  },
  {
    headerTitle: {
      en: "Oral surgery",
      de: "Oralchirurgie",
      hu: "Szájsebészet",
    },
    items: [
      {
        name: {
          en: "Oral surgery consultation",
          de: "Oralchirurgische Beratung",
          hu: "Szájsebészeti konzultáció",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Oral surgery milk tooth removal",
          de: "Oralchirurgie Milchzahnentfernung",
          hu: "Szájsebészeti tejfog eltávolítás",
        },
        price: "15.000,- Ft",
      },
      {
        name: {
          en: "Oral surgery tooth removal",
          de: "Oralchirurgische Zahnentfernung",
          hu: "Szájsebészeti fogeltávolítás",
        },
        price: "35.000,- Ft tól",
      },
      {
        name: {
          en: "Oral surgery implant",
          de: "Oralchirurgisches Implantat",
          hu: "Szájsebészeti implantátum",
        },
        price: "165.000-230.000,- Ft",
      },
      {
        name: {
          en: "Bone replacement",
          de: "Knochenersatz",
          hu: "Csontpótlás",
        },
        price: "50.000 - 90.000,- Ft / fecskendő",
      },
      {
        name: {
          en: "Surgical orthodontic surgery",
          de: "Chirurgische kieferorthopädische Chirurgie",
          hu: "Sebészeti ortodonciai műtét",
        },
        price: "35.000,- Ft",
      },
      {
        name: {
          en: "Other Oral Sugery intervention",
          de: "Andere Oralchirurgie",
          hu: "Egyéb Szájsebészeti beavatkozás",
        },
        price: "Egyedi árajánlat alapján",
      },
      {
        name: {
          en: "Sinus closure",
          de: "Sinusverschluss",
          hu: "Szinusz (arcüreg) zárás",
        },
        price: "20.000,- Ft",
      },
      {
        name: {
          en: "CT evaluation",
          de: "CT-Auswertung",
          hu: "CT kiértékelés",
        },
        price: "10.000,- Ft",
      },
      {
        name: { en: "Stripping", de: "Strippen", hu: "Sztrippelés" },
        price: "8.000,- Ft",
      },
      {
        name: { en: "Arc replacement", de: "Bogenersatz", hu: "Ívcsere" },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Electrocautery treatment",
          de: "Elektrokauterbehandlung",
          hu: "Elektrokauteres kezelés",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Cavity Lifting",
          de: "Hohlraumlifting",
          hu: "Arcüreg emelés",
        },
        price: "160.000,- Ft -tól",
      },
    ],
  },
  {
    headerTitle: {
      en: "Periodontics",
      de: "Parodontologie",
      hu: "Parodontológia",
    },
    items: [
      {
        name: {
          en: "CT evaluation",
          de: "CT-Auswertung",
          hu: "CT kiértékelés",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Initial evaluation",
          de: "Erstbewertung",
          hu: "Kezdeti kiértékelés",
        },
        price: "25.000,- Ft",
      },
      {
        name: {
          en: "Reassessment",
          de: "Újra kiértékelés",
          hu: "Újra kiértékelés",
        },
        price: "20.000,- Ft",
      },
      {
        name: {
          en: "Periodontal herniation per tooth",
          de: "Parodontalbruch pro Zahn",
          hu: "Parodontális küret foganként",
        },
        price: "12.000,- Ft",
      },
      {
        name: {
          en: "Periodontal horn per quadrant",
          de: "Parodontalhorn pro Quadrant",
          hu: "Parodontális küret kvadránsonként",
        },
        price: "32.000,- Ft",
      },
      {
        name: {
          en: "Periodontal surgery",
          de: "Parodontalchirurgie",
          hu: "Parodontológiai műtét",
        },
        price: "Egyedi árajánlat alapján",
      },
    ],
  },
  {
    headerTitle: { en: "Restorations", de: "Restaurationen", hu: "Pótlások" },
    items: [
      {
        name: {
          en: "Pin Socket -  fiberglass",
          de: "Anschluss - Fiberglas",
          hu: "Csapcsonk - Üvegszálas",
        },
        price: "30.000,- Ft",
      },
      {
        name: {
          en: "Pin Socket - molded",
          de: "Stecker - gegossen",
          hu: "Csapcsonk - Öntött",
        },
        price: "30.000,- Ft",
      },
      {
        name: {
          en: "Temporary dentures",
          de: "Provisorischer Zahnersatz",
          hu: "Ideiglenes fogsor",
        },
        price: "120.000,- Ft",
      },
      {
        name: {
          en: "Full acrylic denture as jawbone",
          de: "Vollprothese aus Acryl als Kieferknochen",
          hu: "Teljes akril fogsor állcsontoként",
        },
        price: "210.000,- Ft",
      },
      {
        name: {
          en: "Metal plate denture as jawbone",
          de: "Metallplattenprothese als Kieferknochen",
          hu: "Fémlemezes fogsor állcsontoként",
        },
        price: "230.000,- Ft",
      },
      {
        name: {
          en: "Denture repair",
          de: "Reparatur von Zahnersatz",
          hu: "Fogsorjavítás",
        },
        price: "25.000 - 35.000,- Ft",
      },
      {
        name: {
          en: "Crown removal (per anchor crown)",
          de: "Kronenentfernung (pro Ankerkrone)",
          hu: "Korona eltávolítás (horgonykoronánként)",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Crown Adhesion (per anchor crown)",
          de: "Kronenhaftung (pro Ankerkrone)",
          hu: "Korona visszaragasztása (horgonykoronánként)",
        },
        price: "15.000,- Ft",
      },
      {
        name: {
          en: "Temporary clinics crown",
          de: "Temporäre Kronkliniken",
          hu: "Korona ideiglenes rendelői",
        },
        price: "10.000,- Ft",
      },
      {
        name: {
          en: "Technical acrylate / PMMA",
          de: "Technisches Acrylat / PMMA",
          hu: "Technikai akrilát/PMMA",
        },
        price: "20.000,- Ft",
      },
      {
        name: {
          en: "Technical metal frame with composite cover",
          de: "Technischer Metallrahmen mit Verbundabdeckung",
          hu: "Technikai fémvázas kompozit leplezéssel",
        },
        price: "24.000,- Ft/db",
      },
      {
        name: {
          en: "Inlay/onlay - gradia",
          de: "Inlay/onlay - gradia",
          hu: "Inlay/onlay - gradia",
        },
        price: "80.000,- Ft",
      },
      {
        name: {
          en: "Inlay/onlay - zircon",
          de: "Inlay/onlay - Zirkon",
          hu: "Inlay/onlay - cirkon",
        },
        price: "90.000,- Ft",
      },
      {
        name: {
          en: "Removable dentures",
          de: "Herausnehmbarer Zahnersatz",
          hu: "Kivehető fogpótlások",
        },
        price: "Egyedi árajánlat alapján",
      },
    ],
  },
  {
    headerTitle: {
      en: "Porcelain crowns",
      de: "Porzellankronen",
      hu: "Porcelán koronák",
    },
    items: [
      {
        name: {
          en: "Zircon based ceramic crown",
          de: "Keramikkrone auf Zirkonbasis",
          hu: "Cirkon alapú kerámia korona",
        },
        price: "95.000,- Ft",
      },
      {
        name: {
          en: "Metal ceramic crown, nickel free",
          de: "Metallkeramikkrone ist nickelfrei",
          hu: "Fémkerámia korona nikkelmentes alapú",
        },
        price: "85.000,- Ft",
      },
      {
        name: {
          en: "Metal ceramic crown, titanium based",
          de: "Die Keramikkrone aus Metall basiert auf Titan",
          hu: "Fémkerámia korona titán alapú",
        },
        price: "90.000,- Ft",
      },
      {
        name: {
          en: "Frilled shoulder crown",
          de: "Gekräuselte Schulterkrone",
          hu: "Frézélt vállas korona",
        },
        price: "95.000,- Ft",
      },
      {
        name: {
          en: "Crown for Implants",
          de: "Krone für Implantate",
          hu: "Implantátumokra készülő korona",
        },
        price: "105.000,- Ft - tól / implant + titán bázis",
      },
    ],
  },
];
